import { useContext, useState } from 'react';
import { css, styled } from '@nx-kit/styling';
import useTranslation from 'next-translate/useTranslation';
import { useFocusWithin, useHover, usePress } from '@react-aria/interactions';
import { mapNextLocaleToISOLanguageCode } from 'helpers/locale.helper';
import Link from 'components/atoms/Link/Link';
import { NavLevel1 } from 'components/organisms/Header/Header.types';
import { DocumentRelationsContext } from 'contexts/documentRelations/DocumentRelationsContext';
import Level2 from './Level2';
import { HeaderContext } from 'contexts/header/HeaderContext';

const Level1Wrapper = styled.div`
  height: 100%;
`;

const Level1LinkWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Level1TouchTarget = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Level1Link = styled(Link)<{
  isActive: boolean;
  forceHovered: boolean;
}>`
  position: relative;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.global.color.gray900};
  text-decoration: none;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: calc(50% - 30px);
    width: 60px;
    height: 3px;
    background-color: transparent;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.global.color.primary};

      &:after {
        background-color: transparent;
      }
    `}

  ${({ forceHovered }) =>
    forceHovered &&
    css`
      color: ${({ theme }) => theme.global.color.primary};

      &:after {
        background-color: ${({ theme }) => theme.global.color.primary};
      }
    `}
`;

const Level1 = ({ primary, items, index }: NavLevel1 & { index: number }) => {
  const { lang } = useTranslation('common');
  const documentRelations = useContext(DocumentRelationsContext);
  const isoLangCode = mapNextLocaleToISOLanguageCode(lang);

  const f = window.location.pathname.split('/');
  let selectedDocItem;
  if (f.length > 2 && documentRelations) {
    const doc = documentRelations[isoLangCode];

    // @ts-ignore
    selectedDocItem = doc?.find((docItem: any) => {
      return docItem.uid == f[2];
    });
  }

  const hasLevel2 = items.length > 0;
  const { open, setOpen } = useContext(HeaderContext);
  const [, setActive] = useState(false);
  const { pressProps } = usePress({
    onPress: () => setOpen(index),
  });

  const isSelected =
    selectedDocItem && 'uid' in primary.link ? primary.link.uid === selectedDocItem.uid : false;

  const { hoverProps } = useHover({
    onHoverStart: () => {
      setActive(true);
      if (hasLevel2) {
        setOpen(index);
      }
    },
    onHoverEnd: () => {
      setActive(false);
      setOpen(-1);
    },
  });

  const { focusWithinProps } = useFocusWithin({
    onFocusWithin: () => {
      setTimeout(() => {
        setOpen(hasLevel2 ? index : -1);
        setActive(true);
      }, 10);
    },
    onBlurWithin: () => {
      setActive(false);
      setOpen(-1);
    },
  });

  const isActive = open === index;

  return (
    <Level1Wrapper {...hoverProps} {...focusWithinProps}>
      <Level1LinkWrapper>
        <Level1Link url={primary.link} isActive={isActive} forceHovered={isSelected}>
          {primary.link_text}
        </Level1Link>
        {!isActive && hasLevel2 && <Level1TouchTarget {...pressProps} />}
      </Level1LinkWrapper>
      {hasLevel2 && (
        <Level2
          items={items}
          side_link={primary.side_link}
          side_link_image={primary.side_link_image}
          side_link_text={primary.side_link_text}
          side_link_subtext={primary.side_link_subtext}
          index={index}
        />
      )}
    </Level1Wrapper>
  );
};

export default Level1;
