import { useContext } from 'react';
import { useRouter } from 'next/router';
import { css, styled, media } from '@nx-kit/styling';
import { HStack, Spacer } from '@nx-kit/layout';
import useTranslation from 'next-translate/useTranslation';
import useIsSticky from 'hooks/useIsSticky';
import { LanguageSwitcherMobile } from 'components/molecules/LanguageSwitcher';
import { HeaderPropsWithLangLinks, NavLevel1 } from 'components/organisms/Header';
import Accordion from 'components/molecules/Accordion/Accordion';
import { SearchField } from 'components/molecules/SearchField';
import Logo from 'components/atoms/Logo/Logo';
import Link from 'components/atoms/Link/Link';
import Level1 from './Level1';
import Hamburger from './Hamburger';
import { HeaderContext } from 'contexts/header/HeaderContext';
import TagManager from 'react-gtm-module';

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: ${({ theme }) => theme.global.zIndex.header};
`;

const Flyout = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 70px;
  width: 100%;
  height: calc(100vh - 70px);
  padding: 22px 30px 100px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.global.color.white};

  ${({ isOpen, theme }) =>
    isOpen
      ? css`
          transform: none;
          visibility: visible;
          transition: transform 400ms ${theme.global.easing.snappy}, visibility 0s 0s;
        `
      : css`
          transform: translateY(-100%);
          visibility: hidden;
          transition: transform 400ms ${theme.global.easing.snappy}, visibility 0s 400ms;
        `}
`;

const Bar = styled(HStack)<{ isSticky: boolean; isOpen: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: ${({ theme }) => theme.global.zIndex.one};
  transition: background-color 200ms 400ms;
  ${({ isSticky, isOpen, theme }) =>
    (isSticky || isOpen) &&
    css`
      background-color: ${theme.global.color.white};
      transition: none;
    `}
  ${({ isSticky }) =>
    isSticky &&
    css`
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
    `}
`;

const Level1Wrapper = styled.div`
  margin-bottom: 30px;
`;

const NavSpacer = styled.div`
  height: 70px;
`;

const StyledLogo = styled(Logo)`
  margin-left: 14px;
  width: 100px;
`;

const MetaLink = styled(Link)`
  margin: 0 0 20px;
`;

const DonateLinkNavigation = styled(Link)`
  outline: none !important;
  margin-right: 40px;
  display: none;

  ${media('sm')} {
    display: flex;
  }
`;

const DonateLinkMenu = styled(Link)`
  margin: 10px 0 50px;
  width: 100%;
  padding: 0 auto;
  display: flex;
  justify-content: center;
`;

const SearchFieldStyled = styled(SearchField)`
  margin-bottom: 30px;
`;

const MobileNav = ({ className, data, languageOptions }: HeaderPropsWithLangLinks) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const { isSticky, isStickyProps } = useIsSticky();
  const { open, setOpen } = useContext(HeaderContext);
  const isOpen = open >= 0;

  const donateLink = {
    url: t('links.donateNow.url'),
    link_type: 'Web',
    label: t('links.donateNow.label'),
  };

  return (
    <>
      <NavSpacer {...isStickyProps} />
      <StyledHeader className={className}>
        <Bar isOpen={isOpen} isSticky={isSticky}>
          <Link url={{ link_type: 'Web', url: `/${locale}` }}>
            <StyledLogo />
          </Link>
          <Spacer />
          {!isOpen && (
            <DonateLinkNavigation
              onPress={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'Donation Made',
                    action: 'donationMade',
                    label: 'donationAmount',
                    category: 'donationMade',
                  },
                });
              }}
              icon="DONATE"
              skin="secondary"
              text={donateLink.label}
              url={donateLink}
            />
          )}
          <p>{t('menuLabel')}</p>
          <Hamburger
            isOpen={isOpen}
            onPress={() => {
              setOpen(isOpen ? -1 : 0);
            }}
          />
        </Bar>
        <Flyout isOpen={isOpen}>
          <SearchFieldStyled />
          <Level1Wrapper>
            <Accordion skin="navigation">
              {data.body.map((slice, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Level1
                  {...(slice as unknown as NavLevel1)}
                  key={`level1-${(slice as unknown as NavLevel1).primary.link.url}-${index}`}
                />
              ))}
            </Accordion>
          </Level1Wrapper>
          {data.meta_links.map(({ link, link_text }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MetaLink skin="navMetaMobile" url={link} key={`meta-${link.url}-${index}`}>
              {link_text}
            </MetaLink>
          ))}
          <DonateLinkMenu icon="DONATE" skin="primary" text={donateLink.label} url={donateLink} />
          <LanguageSwitcherMobile withWhiteSkin options={languageOptions} />
        </Flyout>
      </StyledHeader>
    </>
  );
};

export default MobileNav;
