import { styled } from '@nx-kit/styling';
import { Button } from '@nx-kit/button';
import { StyledInputText } from 'components/atoms/TextInput';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  height: 46px;
  min-width: 140px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.global.color.gray500};
  background-color: ${({ theme }) => theme.global.color.white};

  &&& ${StyledInputText} {
    border: none;
    background-color: white;
    height: 44px;
    padding: 0;
    margin: 0;

    &:focus {
      border: none;
    }
  }
`;

export const FormStyled = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SearchButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.global.color.primary};
  margin-top: 5px;
  width: auto !important;
`;
