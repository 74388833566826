import { useContext } from 'react';
import { css, styled } from '@nx-kit/styling';
import { usePress } from '@react-aria/interactions';
import MainNav from './MainNav';
import Meta from './Meta';
import { HeaderPropsWithLangLinks } from 'components/organisms/Header';
import { NavLevel1 } from 'components/organisms/Header/Header.types';
import { HeaderContext } from 'contexts/header/HeaderContext';

const StyledHeader = styled.header`
  z-index: ${({ theme }) => theme.global.zIndex.header};
`;

const Backdrop = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.global.zIndex.header};
  background: rgba(0, 0, 0, 20%);
  backdrop-filter: blur(10px);

  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms, visibility 0s 200ms;

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      visibility: visible;
      transition: opacity 200ms, visibility 0s 0s;
    `}
`;

const DesktopNav = ({ className, data, languageOptions }: HeaderPropsWithLangLinks) => {
  const { open, setOpen } = useContext(HeaderContext);
  const { pressProps } = usePress({
    onPress: () => setOpen(-1),
  });
  return (
    <>
      <Backdrop {...pressProps} open={open >= 0} />

      <StyledHeader className={className}>
        <Meta links={data.meta_links} languageOptions={languageOptions} />
        <MainNav slices={data.body as unknown as Array<NavLevel1>} />
      </StyledHeader>
    </>
  );
};

export default DesktopNav;
