import Link from 'components/atoms/Link/Link';
import { NavLevel3 } from 'components/organisms/Header/Header.types';

const Level3 = ({ items }: { items: Array<NavLevel3> }) => (
  <>
    {items.map(({ text, spans }, index) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <Link skin="navLevel3" url={spans[0]?.data} key={`level3-${spans[0]?.data?.url}-${index}`}>
        {text}
      </Link>
    ))}
  </>
);

export default Level3;
