/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { PrismicExternalLink, PrismicLink } from 'prismic/types';
import NavLevel1 from './partials/desktop/MainNav';
import DesktopNav from './partials/desktop/DesktopNav';
import MobileNav from './partials/mobile/MobileNav';
import { LanguageOptions, generateLanguageOptions } from 'helpers/header.helper';
import { HeaderContext } from 'contexts/header/HeaderContext';

export interface HeaderProps {
  className?: string;
  alternateLanguages: PrismicLink[] | [];
  data: {
    meta_links: Array<{ link: PrismicLink | PrismicExternalLink; link_text: string }>;
    body: Array<typeof NavLevel1>;
  };
}

export interface HeaderPropsWithLangLinks extends HeaderProps {
  languageOptions: LanguageOptions;
}

function debounce(fn: () => void, ms: number) {
  let timer: number | undefined;
  return () => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = undefined;
      // @ts-ignore -> apply is too complex for TS
      fn.apply(this, arguments);
    }, ms);
  };
}

const Header: React.FC<HeaderProps> = (props) => {
  const { setOpen } = useContext(HeaderContext);
  const [showMobile, setShowMobile] = useState(true);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      if (window?.innerWidth < 1396) {
        setShowMobile(true);
      } else {
        setShowMobile(false);
      }
    }, 200);

    debouncedHandleResize();

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  const languageOptions = generateLanguageOptions(props.alternateLanguages);

  const router = useRouter();

  useEffect(() => {
    const onPageChange = () => setOpen(-1);
    router.events.on('routeChangeStart', onPageChange);
    return () => {
      router.events.off('routeChangeStart', onPageChange);
    };
  }, [setOpen]);

  return (
    <>
      {showMobile ? (
        <MobileNav {...props} languageOptions={languageOptions} />
      ) : (
        <DesktopNav {...props} languageOptions={languageOptions} />
      )}
    </>
  );
};

export default Header;
