import { styled } from '@nx-kit/styling';
import Link from 'components/atoms/Link/Link';
import Level2 from './Level2';
import { NavLevel1 } from 'components/organisms/Header/Header.types';
import { AccordionItem } from 'components/molecules/Accordion/Accordion';

const Level1Wrapper = styled.div`
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 9%);
`;

const Level1 = ({ primary, items }: NavLevel1) => {
  const hasLevel2 = items.length > 0;
  return (
    <Level1Wrapper>
      <Link skin="navLevel1" url={primary.link}>
        {primary.link_text}
      </Link>
      {hasLevel2 && (
        <AccordionItem title={<span className="visually-hidden">{primary.link_text}</span>}>
          <Level2 items={items} />
        </AccordionItem>
      )}
    </Level1Wrapper>
  );
};

export default Level1;
