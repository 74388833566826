import { styled } from '@nx-kit/styling';
import Link from 'components/atoms/Link/Link';
import { NavLevel2, NavLevel2Link } from 'components/organisms/Header/Header.types';
import Accordion, { AccordionItem } from 'components/molecules/Accordion/Accordion';
import Level3 from './Level3';

const Level2Wrapper = styled.div`
  position: relative;
`;

const Level2LinkWrapper = ({ level_2_link, level_2_link_text, level_3_links }: NavLevel2Link) => {
  let hasLevel3 = false;

  if (level_3_links.length > 0 && level_3_links[0].spans.length > 0) {
    hasLevel3 = true;
  } else if (level_3_links.length > 1) {
    hasLevel3 = true;
  }

  return (
    <Level2Wrapper>
      <Link skin="navLevel2" url={level_2_link}>
        {level_2_link_text}
      </Link>
      {hasLevel3 && (
        <AccordionItem title={<span className="visually-hidden">{level_2_link_text}</span>}>
          <Level3 items={level_3_links} />
        </AccordionItem>
      )}
    </Level2Wrapper>
  );
};

const Level2 = ({ items }: Pick<NavLevel2, 'items'>) => (
  <Accordion skin="navigation">
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Level2LinkWrapper {...item} key={`level2-${item.level_2_link.url}-${index}`} />
    ))}
  </Accordion>
);

export default Level2;
