import { useContext } from 'react';
import { styled, css } from '@nx-kit/styling';
import isEmpty from 'lodash/isEmpty';
import Link from 'components/atoms/Link/Link';
import Teaser from 'components/molecules/Teaser/Teaser';
import { NavLevel2, NavLevel2Link } from 'components/organisms/Header/Header.types';
import Level3 from './Level3';
import { HeaderContext } from 'contexts/header/HeaderContext';

const Level2Wrapper = styled.div<{ open: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 400px;
  z-index: ${({ theme }) => theme.global.zIndex.minusOne};

  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms, visibility 0s 200ms;
  ${({ open }) =>
    open &&
    css`
      z-index: ${({ theme }) => theme.global.zIndex.zero};
      visibility: visible;
      opacity: 1;
      transition: opacity 200ms;
    `}
`;

const Ul = styled.ul<{ open: boolean }>`
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;
  column-gap: 44px;
  padding: 0 max(50% - 640px, 60px) 60px;

  transform: translateY(-100%);
  transition: transform 400ms;

  ${({ open }) =>
    open &&
    css`
      transform: none;
    `}
`;

const Li = styled.li<{ hasLevel3: boolean }>`
  position: relative;
  padding: 15px 0;
  align-self: start;
  height: auto;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.global.color.gray100};
  }

  ${({ hasLevel3 }) =>
    hasLevel3 &&
    css`
      grid-row: 1 / span 4;
    `}
`;

const SideLink = styled(Teaser)`
  margin-top: 30px;
  grid-row: 1 / span 4;
`;

const Level2Link = styled(Link)`
  display: block;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: ${({ theme }) => theme.global.color.gray900};
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.global.color.primary};
  }
`;

const Level2LinkWrapper = ({ level_2_link, level_2_link_text, level_3_links }: NavLevel2Link) => {
  let hasLevel3 = false;

  if (level_3_links.length > 0 && level_3_links[0].spans.length > 0) {
    hasLevel3 = true;
  } else if (level_3_links.length > 1) {
    hasLevel3 = true;
  }
  return (
    <Li hasLevel3={hasLevel3}>
      <Level2Link url={level_2_link}>{level_2_link_text}</Level2Link>
      {hasLevel3 && <Level3 items={level_3_links} />}
    </Li>
  );
};

const Level2 = ({
  items,
  side_link,
  side_link_image,
  side_link_text,
  side_link_subtext,
  index,
}: NavLevel2) => {
  const { open } = useContext(HeaderContext);
  return (
    <Level2Wrapper open={open === index}>
      <Ul open={open >= 0} data-level="2">
        {/* eslint-disable-next-line react/no-array-index-key */}
        {items.map((item, level2index) => (
          <Level2LinkWrapper {...item} key={`level2-${item.level_2_link.url}-${level2index}`} />
        ))}
        {!isEmpty(side_link_image) && (
          <SideLink
            image={side_link_image}
            text={side_link_text}
            url={side_link}
            subtext={side_link_subtext}
          />
        )}
      </Ul>
    </Level2Wrapper>
  );
};

export default Level2;
