import { styled } from '@nx-kit/styling';
import Link from 'components/atoms/Link/Link';
import { NavLevel3 } from 'components/organisms/Header/Header.types';

const Ul = styled.ul`
  list-style: none;
  padding: 5px 0 0;
`;

const Li = styled.li`
  padding: 5px 0;
`;

const Level3Link = styled(Link)`
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.global.color.gray900};
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.global.color.primary};
  }
`;

const Level3 = ({ items }: { items: Array<NavLevel3> }) => {
  return (
    <Ul data-level="3">
      {items.map(({ text, spans }, index) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <Li key={`level3-${spans[0]?.data?.url}-${index}`}>
          <Level3Link url={spans[0]?.data}>{text}</Level3Link>
        </Li>
      ))}
    </Ul>
  );
};

export default Level3;
