import React from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { PagesPaths } from 'types/pages';
import { cleanRouterQuery, mapObjectToQueryString } from 'helpers/url';
import { SearchFormTypes } from 'components/organisms/SearchContent/SearchContent.types';
import { TextInput } from 'components/atoms/TextInput';
import Icon from 'components/atoms/Icon/Icon';
import { SearchFieldProps, SearchFieldTypes } from './SearchField.types';
import { FormStyled, SearchButtonStyled, Wrapper } from './SearchField.styles';

export const SearchField: React.FC<SearchFieldProps> = ({ className }) => {
  const router = useRouter();
  const {
    register,
    formState: {},
    handleSubmit,
  } = useForm<SearchFieldTypes>();

  const { t, lang } = useTranslation('common');

  const submitHandler = async (data: SearchFormTypes) => {
    const queryObj = cleanRouterQuery(router.query);
    queryObj.key = data.key;
    const queryString = mapObjectToQueryString(queryObj);
    router.push(`/${lang}/${PagesPaths.search}/${queryString}`, undefined);
  };

  return (
    <Wrapper className={className}>
      <FormStyled onSubmit={handleSubmit(submitHandler)}>
        <TextInput
          label={t('searchInputPlaceholder')}
          type="search"
          name="key"
          defaultValue=""
          placeholder={t('searchInputNavPlaceholder')}
          register={register}
        />
        <SearchButtonStyled
          aria-label={t('searchInputPlaceholder')}
          onPress={() => {
            handleSubmit(submitHandler);
          }}
          type="submit"
        >
          <Icon name="LOUPE" />
        </SearchButtonStyled>
      </FormStyled>
    </Wrapper>
  );
};
