import { css, styled } from '@nx-kit/styling';
import { Button } from '@nx-kit/button';

type HamburgerProps = {
  isOpen: boolean;
  onPress?: () => any;
};

const StyledButton = styled(Button)`
  width: 55px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.global.color.primary};
`;

const Icon = styled.svg<{ isOpen: boolean }>`
  width: 20px;
  height: 20px;

  line {
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke: currentColor;
    transition: transform 400ms ${({ theme }) => theme.global.easing.snappy};
    transform-origin: 50% 50% 0;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      line:first-of-type {
        transform: rotate(45deg) translateY(4px);
      }
      line:last-of-type {
        transform: rotate(-45deg) translateY(-4px);
      }
    `}
`;

const Hamburger = ({ isOpen, onPress }: HamburgerProps) => (
  <StyledButton onPress={onPress}>
    <span className="visually-hidden">{isOpen ? 'Menü schliessen' : 'Menü öffnen'}</span>
    <Icon viewBox="0 0 20 20" isOpen={isOpen}>
      <line x1={1} x2={19} y1={6} y2={6} />
      <line x1={1} x2={19} y1={14} y2={14} />
    </Icon>
  </StyledButton>
);

export default Hamburger;
